<template>
  <b-row>

    <!-- Owner Name -->
    <b-col sm="6">
      <validation-provider
        #default="validationContext"
        name="Owner Name"
        rules="required"
      >
        <b-form-group
          label="Owner Name"
          label-for="owner-name"
        >
          <b-form-input
            id="owner-name"
            v-model="retailerData.OwnerName"
            :state="getValidationState(validationContext)"
            placeholder="Owner Name"
          />
          <b-form-invalid-feedback>
            {{ validationContext.errors[0] }}
          </b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>
    </b-col>

    <!-- Owner Mobile -->
    <b-col sm="6">
      <validation-provider
        #default="validationContext"
        name="Owner Mobile"
        rules="required"
      >
        <b-form-group
          label="Owner Mobile"
          label-for="owner-mobile"
        >
          <b-form-input
            id="owner-mobile"
            v-model="retailerData.OwnerMobile"
            :state="getValidationState(validationContext)"
            placeholder="Owner Mobile"
          />
          <b-form-invalid-feedback>
            {{ validationContext.errors[0] }}
          </b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>
    </b-col>

    <!-- Business Name -->
    <b-col sm="6">
      <validation-provider
        #default="validationContext"
        name="Business Name"
        rules="required"
      >
        <b-form-group
          label="Business Name"
          label-for="business-name"
        >
          <b-form-input
            id="business-name"
            v-model="retailerData.BusinessName"
            :state="getValidationState(validationContext)"
            placeholder="Business Name"
          />
          <b-form-invalid-feedback>
            {{ validationContext.errors[0] }}
          </b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>
    </b-col>

    <!-- CR Number -->
    <b-col sm="6">
      <validation-provider
        #default="validationContext"
        name="CR Number"
        rules="required"
      >
        <b-form-group
          label="CR Number"
          label-for="cr-number"
        >
          <b-form-input
            id="cr-number"
            v-model="retailerData.CRNumber"
            :state="getValidationState(validationContext)"
            placeholder="CR Number"
          />
          <b-form-invalid-feedback>
            {{ validationContext.errors[0] }}
          </b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>
    </b-col>

    <!-- Is Taxable -->
    <b-col sm="6">
      <b-form-group
        label="Is Taxable"
        label-for="is-taxable"
      >
        <b-form-checkbox
          id="is-taxable"
          v-model="retailerData.is_taxable"
          checked="true"
          name="is-taxable"
          switch
          @input="unsetVatID"
        >
          <span class="switch-icon-left">
            <feather-icon icon="CheckIcon" />
          </span>
          <span class="switch-icon-right">
            <feather-icon icon="XIcon" />
          </span>
        </b-form-checkbox>
      </b-form-group>
    </b-col>

    <!-- Zero VAT -->
    <b-col sm="6">
      <b-form-group
        label="Zero VAT"
        label-for="zero-vat"
      >
        <b-form-checkbox
          id="zero-vat"
          v-model="retailerData.ZeroVat"
          checked="true"
          name="is-taxable"
          switch
        >
          <span class="switch-icon-left">
            <feather-icon icon="CheckIcon" />
          </span>
          <span class="switch-icon-right">
            <feather-icon icon="XIcon" />
          </span>
        </b-form-checkbox>
      </b-form-group>
    </b-col>

    <!-- VAT Id -->
    <b-col
      v-if="retailerData.is_taxable"
      sm="6"
    >
      <validation-provider
        #default="validationContext"
        name="VAT Id"
        rules="required|digits:15"
      >
        <b-form-group
          label="VAT Id"
          label-for="vat-id"
        >
          <b-form-input
            id="vat-id"
            v-model="retailerData.TaxID"
            :state="getValidationState(validationContext)"
            placeholder="VAT Id"
          />
          <b-form-invalid-feedback>
            {{ validationContext.errors[0] }}
          </b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>
    </b-col>

  </b-row>
</template>

<script>
import {
  BRow, BCol, BFormGroup, BFormInput, BFormCheckbox,
  BFormInvalidFeedback,
} from 'bootstrap-vue'
import { ValidationProvider } from 'vee-validate'
import Ripple from 'vue-ripple-directive'
import formValidation from '@core/comp-functions/forms/form-validation'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BFormInvalidFeedback,

    //  Form Validation
    ValidationProvider,
  },

  directives: {
    Ripple,
  },
  props: {
    retailerData: {
      type: Object,
      required: true,
    },
  },

  setup(_, { emit }) {
    const {
      getValidationState,
    } = formValidation()

    const unsetVatID = () => {
      emit('unset-vat-id')
    }

    return {
      //  Form
      getValidationState,

      unsetVatID,
    }
  },
}
</script>
