<template>
  <div>
    <b-card>
      <validation-observer
        #default="{ invalid, handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          @submit.prevent="handleSubmit(updateRetailer)"
        >

          <!-- Actions -->
          <b-row class="mb-4">
            <b-col>
              <b-button
                variant="secondary"
                class="float-right"
                size="sm"
                type="submit"
                :disabled="invalid"
              >
                <span class="text-nowrap">Save Changes</span>
              </b-button>
            </b-col>
          </b-row>

          <retailers-form
            :retailer-data="retailerData"
            @unset-vat-id="unsetVatID"
          />

        </b-form>

      </validation-observer>
    </b-card>

    <!-- Branches -->
    <b-card
      v-if="branchesData && branchesData.length > 0"
      no-body
    >
      <b-row>
        <b-col class="mt-2">

          <h4 class="px-2 mb-2">
            Branches
          </h4>

          <branches-list
            :table-columns="tableColumns"
            :branches="branchesData"
          />

        </b-col>

      </b-row>
    </b-card>

    <!-- Subscription -->
    <b-card
      v-if="retailerData.subscriptions && retailerData.subscriptions.length > 0"
      no-body
    >
      <b-row>
        <b-col class="mt-2">

          <h4 class="px-2 mb-2">
            Subscriptions
          </h4>

          <subscriptions-list :subscriptions="retailerData.subscriptions" />

        </b-col>
      </b-row>
    </b-card>

  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BForm, BButton,
} from 'bootstrap-vue'
import { onUnmounted } from '@vue/composition-api'
import { ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import store from '@/store'
import router from '@/router'
import useRetailersForm from '../retailers-form/useRetailersForm'
import retailerStoreModule from '../retailerStoreModule'
import RetailersForm from '../retailers-form/RetailersForm.vue'
import SubscriptionsList from '../subscriptions/subscriptions-list/SubscriptionsList.vue'
import useBranchesForm from '../branches/branches-form/useBranchesForm'
import BranchesList from '../branches/branches-list/BranchesList.vue'
import useBranchesList from '../branches/branches-list/useBranchesList'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BForm,
    BButton,

    //  Local Components
    RetailersForm,
    SubscriptionsList,
    BranchesList,

    //  Form Validation
    ValidationObserver,
  },
  data() {
    return {
      required,
    }
  },

  setup() {
    const RETAILER_APP_STORE_MODULE_NAME = 'app-retailer'

    // Register module
    if (!store.hasModule(RETAILER_APP_STORE_MODULE_NAME)) store.registerModule(RETAILER_APP_STORE_MODULE_NAME, retailerStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(RETAILER_APP_STORE_MODULE_NAME)) store.unregisterModule(RETAILER_APP_STORE_MODULE_NAME)
    })

    const {
      retailerData,

      fetchRetailer,
      updateRetailer,
      unsetVatID,
    } = useRetailersForm()

    const {
      branchesData,

      fetchBranches,
    } = useBranchesForm()

    const { tableColumns } = useBranchesList()

    fetchRetailer(router.currentRoute.params.id)

    fetchBranches(router.currentRoute.params.id)

    return {
      //  Data
      retailerData,
      branchesData,

      tableColumns,

      //  Action
      updateRetailer,
      unsetVatID,
    }
  },
}
</script>
